@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./styles/animation";

@layer base {
  html {
    font-family: "Roboto", sans-serif;
    @apply text-regal-blue;
    @apply bg-white-400;
  }
}

app-page-layout .page-title {
  @apply pb-9;
  @apply font-bold;
  @apply text-2xl;
}

.blue-snackbar {
  background: #5a73ce;
  color: white;
}

.green-snackbar {
  background: #00d46a;
  color: white;
}

.red-snackbar {
  background: #d9534f;
}

.disabled-text {
  @apply text-white-300;
}

.active-filter {
  svg {
    stroke: #939393;
    stroke-width: 100;
  }
}

.mat-menu-button {
  @apply text-2xl;
  @apply border;
  @apply rounded;
  @apply px-4;
  @apply cursor-pointer;
  @apply active:scale-95;
  @apply py-1;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: #00304a;
  border: 50px none #ecfdfc;
}

::-webkit-scrollbar-thumb:hover {
  background: #00304a;
}

::-webkit-scrollbar-thumb:active {
  background: #00304a;
}

::-webkit-scrollbar-track {
  background: #ececec;
  border: 0px none #ecfdfc;
  border-radius: 150px;
}

::-webkit-scrollbar-track:hover {
  background: #ececec;
}

::-webkit-scrollbar-track:active {
  background: #c4c4c4;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

app-main-content-layout {
  .title {
    @apply border-b;
    @apply border-white-500;
    @apply pb-6;
    @apply font-bold;
  }

  .endLine {
    @apply border-b;
    @apply border-white-500;
    @apply pb-6;
  }

  .twoCols {
    @apply grid;
    @apply grid-cols-2;
    @apply gap-4;
  }
}

.twoCols {
  @apply grid;
  @apply grid-cols-2;
  @apply gap-6;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.formInput {
  @apply outline-none;
  @apply w-full;
  @apply border;
  @apply border-white-500;
  @apply rounded;
  @apply p-[0.625rem];
}

.disabled-formInput {
  @apply bg-white-500;
}

.info-box {
  padding: 0.75rem;
  background-color: #e2e2e2;
  border-radius: 0.25rem;

  span {
    color: #848484;
    margin-right: 0.75rem;
  }
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #0076cb;
}

.mat-ripple-element {
  display: none !important;
}

.mat-ripple-element {
  display: none !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #0076cb;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #0076cb;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #b7e1cb !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #109b50 !important;
}

.mat-slide-toggle.mat-disabled {
  opacity: 1 !important;

  .mat-slide-toggle-content {
    color: #bbbbbb;
  }
}
