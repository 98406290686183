@keyframes placeload {
  0% {
    background-position: -1200px 0;
  }

  100% {
    background-position: 1200px 0;
  }
}

.placeholder-animation {
  animation-duration: 2.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeload;
  animation-timing-function: linear;
  background: #FAFAFA;
  background: linear-gradient(to right, #FAFAFA 8%, #E2E2E2 18%, #FAFAFA 33%);
  position: relative;
  background-size: 1200px 104px;
}
